import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, CircularProgress } from '@mui/material';
import { BLACK_COLOR, LIGHT_GREY } from '../../common/constants/colors';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import Button from '@mui/material/Button';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { Customer } from '../../models/customer.model';
import CustomerProfileForm from './components/CustomerProfileForm';

const CustomerProfiles = () => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const navigate = useNavigate();
  const company = useAppSelector(selectAllCompanies)[0];
  const customers: Customer[] = company?.customers?.slice() || [];
  const customersSorted =
    customers.length > 0
      ? customers.sort((a, b) => a.customer_name.localeCompare(b.customer_name))
      : [];
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (company) {
      setLoading(false);
    }
  }, [company]);

  return (
    <React.Fragment>
      {customerId ? (
        <CustomerProfileForm />
      ) : (
        <React.Fragment>
          <div className='header-wrapper employees-page-header d-md-flex d-lg-flex justify-content-md-between justify-content-lg-between align-items-md-start align-items-lg-start mb-3'>
            <h1 className='mb-2 mb-md-0 mb-lg-0 fs-2'>{t('customerProfiles.header')}</h1>
            <div className='controls-wrapper text-center text-md-left text-lg-left'>
              <Button
                type='button'
                variant='outlined'
                size='large'
                onClick={() => navigate(ROUTES.CUSTOMER_PROFILE_CREATE)}
              >
                <PersonAddAltOutlinedIcon sx={{ width: 30, height: 30 }} color='primary' />
                <span>{t('buttons.addCustomer')}</span>
              </Button>
            </div>
          </div>
          <div className='invite-employees-items employees-list d-flex justify-content-center justify-content-md-start flex-wrap'>
            {loading ? (
              <div className='circular-progress-container'>
                <CircularProgress size={60} />
              </div>
            ) : customersSorted.length > 0 ? (
              customersSorted.map((customer) => (
                <div
                  role='button'
                  tabIndex={-1}
                  key={customer.customer_name}
                  className='invite-employees-item'
                  onClick={() => navigate(ROUTES.CUSTOMER_PROFILES + '/' + customer.id)}
                  onKeyUp={(e) => console.log(e)}
                >
                  <div className='invite-employees-item-avatar d-flex justify-content-center'>
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        fontSize: '2rem',
                        backgroundColor: LIGHT_GREY,
                        color: BLACK_COLOR,
                      }}
                    >{`${customer?.customer_name?.[0]}`}</Avatar>
                  </div>
                  <div className='invite-employees-item-name'>{customer.customer_name}</div>
                  {/*<Grid item className='invite-employees-item-role'>
                {employee.groups.map((group) => (
                    <div key={group.name}>{group.name}</div>
                ))}
              </Grid>*/}
                  {/*<div className='invite-employees-item-contacts'>{}</div>*/}
                </div>
              ))
            ) : (
              <div className='no-employees-message text-center mt-5 ps-5 pe-5'>
                <p className='mb-5 fs-5'>{t('customerProfiles.noItemsSubheader1')}</p>
                <p className='mb-0 fs-5 fst-italic'>{t('customerProfiles.noItemsSubheader2')}</p>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CustomerProfiles;
