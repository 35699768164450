import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, IconButton } from '@mui/material';
import { InviteEmployeePanel } from '../InviteEmployeePanel';
import { Company } from '../../../../models/company.model';
import {
  fetchCategories,
  selectAllCategories,
} from '../../../../slices/categories/categoriesSlice';
import { NWClient } from '../../../../client/NWClient';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { User } from '../../../../models/user.model';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from '../../../../common/constants/routes';
import { RolesGate } from '../../../../common/roles-gate';
import { GROUPS } from '../../../../common/constants/groups';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useTranslation } from 'react-i18next';
import { DashboardLinks } from '../DashboardLinks';

interface AdminDashboardPanelProp {
  currentUser: User;
}

export const AdminDashboardPanel = ({ currentUser }: AdminDashboardPanelProp) => {
  const token = localStorage.getItem('access_token');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<Company>(null);
  const [fetchCategoriesDispatched, setFetchCategoriesDispatched] = useState<boolean>(false);
  const categories = useSelector(selectAllCategories);
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      if (currentUser.email) {
        if (categories.length === 0 && !fetchCategoriesDispatched) {
          setFetchCategoriesDispatched(true);
          dispatch(fetchCategories(token));
        }
        NWClient.getCurrentCompany(token).then((res) => {
          setCompany(Array.isArray(res) ? res?.[0] : res);
          setLoading(false);
        });
      }
    } else {
      navigate(ROUTES.LOGIN);
    }
  }, [token, currentUser, dispatch, fetchCategoriesDispatched, categories, navigate]);

  return loading ? (
    <CircularProgress size={60} />
  ) : (
    <>
      <DashboardLinks company={company} />
      <Grid container justifyContent='center'>
        <RolesGate group={GROUPS.COMPANY_ADMIN}>
          {company && <InviteEmployeePanel employees={company?.members} />}
          {!company && (
            <Grid container className='invite-employees-panel'>
              <Grid container item alignItems='center' justifyContent='center'>
                <IconButton
                  style={{ borderRadius: 30, padding: 20 }}
                  onClick={() => navigate(ROUTES.COMPANY_CREATE)}
                >
                  <AddBusinessIcon sx={{ width: 30, height: 30 }} color='primary' />
                  <span style={{ marginLeft: 10 }}>{t('buttons.createCompany')}</span>
                </IconButton>
              </Grid>
            </Grid>
          )}
        </RolesGate>
      </Grid>
    </>
  );
};
