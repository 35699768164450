import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { useAppSelector } from '../hooks/useAppSelector';
import { Supplier } from '../../models/supplier.model';
import { Customer } from '../../models/customer.model';

export const NwBreadCrumbs = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId } = useParams();
  const { supplierId } = useParams();
  const { customerId } = useParams();
  const pathnames = decodeURI(pathname)
    .split('/')
    .filter((x) => x);
  const company = useAppSelector(selectAllCompanies)[0];
  const user = userId ? company?.members.find((el) => el.id === Number(userId)) : null;
  const userName = user ? user.first_name + ' ' + user.last_name : null;
  const supplier: Supplier = supplierId
    ? company?.suppliers.find((el) => el.id === Number(supplierId))
    : null;
  const supplierName = supplier ? supplier.name : null;
  const customer: Customer = customerId
    ? company?.customers.find((el) => el.id === Number(customerId))
    : null;
  const customerName = customer ? customer.customer_name : null;
  const nameBreadcrumb = () => {
    if (userId) {
      return userName;
    } else if (supplierId) {
      return supplierName;
    } else if (customerId) {
      return customerName;
    }
  };
  return (
    <Breadcrumbs className='breadcrumbs' separator={<ChevronRightIcon />} aria-label='breadcrumb'>
      {pathnames.length > 0 ? (
        <Link onClick={() => navigate('/')}>{t('breadcrumbs.home')}</Link>
      ) : (
        <h3 className='nw-app-bar-toolbar-title'>{t('breadcrumbs.home')}</h3>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography aria-current='page' key={name}>
            {name === userId || name === customerId || name === supplierId
              ? nameBreadcrumb()
              : name.replaceAll('-', ' ')}
          </Typography>
        ) : (
          <Link key={name} onClick={() => navigate(routeTo)}>
            {name.replaceAll('-', ' ')}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
