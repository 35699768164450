import React, { ChangeEvent, useState, useEffect } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { ProgressOverlay } from '../../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Divider, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import { fetchCompanies, selectAllCompanies } from '../../../slices/companies/companiesSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { NWClient } from '../../../client/NWClient';
import { toast } from 'react-toastify';
import 'dayjs/locale/en-gb';
import { CURRENCIES_LIST } from '../../../common/constants/currencies';
import { ROUTES } from '../../../common/constants/routes';
import { Customer } from '../../../models/customer.model';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogItem from '../../../common/dialog-item/DialogItem';
import CustomerIcon from '../../../../static/assets/svg/menu/customers.svg';

const CustomerProfileForm = () => {
  const { customerId } = useParams();
  const company = useAppSelector(selectAllCompanies)[0];
  const customer = company?.customers?.find((el) => el.id === Number(customerId));
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('access_token');
  const [pendingStatus, setPendingStatus] = useState(false);
  const [name, setName] = useState<string | null>('');
  const [contactName, setContactName] = useState<string | null>('');
  const [contactNumber, setContactNumber] = useState<string | null>('');
  const [emailAddress, setEmailAddress] = useState<string | null>('');
  const [websiteOrSocial, setWebsiteOrSocial] = useState<string | null>('');
  const [physicalAddress, setPhysicalAddress] = useState<string | null>('');
  const [postalAddress, setPostalAddress] = useState<string | null>('');
  const [industryType, setIndustryType] = useState<string | null>('');
  const [preferredContactMethod, setPreferredContactMethod] = useState<string | null>('');
  const [preferredCurrency, setPreferredCurrency] = useState<string | null>('');
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [deleteCustomerMessageVisible, setDeleteCustomerMessageVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canSubmit = Boolean(name);

  const updateCustomer = () => {
    setPendingStatus(true);
    const data = {
      company: company.id,
      customer_name: name,
      contact_name: contactName,
      contact_number: contactNumber,
      email_address: emailAddress,
      website: websiteOrSocial,
      physical_address: physicalAddress,
      postal_address: postalAddress,
      industy_type: industryType,
      preferred_contact_method: preferredContactMethod,
      preferred_currency: preferredCurrency,
    };

    NWClient.put(token, 'customer', Number(customerId), data, true, true)
      .then(() => {
        dispatch(fetchCompanies(token));
        toast.success(t('messages.customerUpdated'), { theme: 'colored' });
        setEditMode(false);
        setPendingStatus(false);
      })
      .catch((error) => {
        setPendingStatus(false);
        setFieldsErrors(error.response.data);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const data = {
      company: company.id,
      customer_name: name,
      contact_name: contactName,
      contact_number: contactNumber,
      email_address: emailAddress,
      website: websiteOrSocial,
      physical_address: physicalAddress,
      postal_address: postalAddress,
      industy_type: industryType,
      preferred_contact_method: preferredContactMethod,
      preferred_currency: preferredCurrency,
    };
    if (customerId) {
      updateCustomer();
    } else {
      NWClient.post(token, 'customer', data)
        .then((res: Customer) => {
          dispatch(fetchCompanies(token));
          toast.success(t('messages.customerCreated'), { theme: 'colored' });
          navigate(ROUTES.CUSTOMER_PROFILES + '/' + res.id);
        })
        .catch((error) => {
          setPendingStatus(false);
          setFieldsErrors(error.response.data);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    }
  };

  const deleteCustomer = () => {
    setPendingStatus(true);
    NWClient.delete(token, 'customer', customerId)
      .then(() => {
        toast.success(t('messages.customerDeleted'), { theme: 'colored' });
        dispatch(fetchCompanies(token))
          .unwrap()
          .then(() => {
            navigate(ROUTES.CUSTOMER_PROFILES);
          })
          .catch(() => {
            setPendingStatus(false);
            toast.error(t('messages.errorOccurred'), { theme: 'colored' });
          });
      })
      .catch(() => {
        setPendingStatus(false);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
  };

  const setDetails = () => {
    if (customer) {
      setName(customer.customer_name);
      setContactName(customer.contact_name);
      setContactNumber(customer.contact_number);
      setEmailAddress(customer.email_address);
      setPhysicalAddress(customer.physical_address);
      setPostalAddress(customer.postal_address);
      setWebsiteOrSocial(customer.website);
      setIndustryType(customer.industy_type);
      setPreferredContactMethod(customer.preferred_contact_method);
      setPreferredCurrency(customer.preferred_currency);
    }
  };

  useEffect(() => {
    if (company) {
      setDetails();
      setLoading(false);
    } else {
      dispatch(fetchCompanies(token))
        .then(() => {
          setDetails();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [company, dispatch, token]);
  return (
    <>
      {loading ? (
        <div className='circular-progress-container'>
          <CircularProgress size={60} />
        </div>
      ) : (
        <div className='profile-details-wrapper'>
          <Grid>
            <div>
              <ValidatorForm onSubmit={handleSubmit}>
                <div className='basic-info'>
                  <Grid
                    container
                    item
                    className='setting-panel-header justify-content-between'
                    alignItems='center'
                  >
                    <div className='d-flex align-items-start header'>
                      <CustomerIcon />
                      <h2>{t('customerTabs.customerDetails')}</h2>
                    </div>
                    {customer && (
                      <div className='d-flex flex-wrap buttons'>
                        <Button
                          style={{ marginLeft: '0.5rem' }}
                          variant='contained'
                          size='large'
                          onClick={() => {
                            if (editMode) {
                              updateCustomer();
                            } else {
                              setEditMode(true);
                            }
                          }}
                        >
                          {!editMode ? t('buttons.edit') : t('buttons.update')}
                        </Button>
                      </div>
                    )}
                  </Grid>
                  <Divider />
                  <div className='setting-panel-body'>
                    <Grid container className='form-controls' spacing={4}>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.customerName')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                          name='name'
                          value={name}
                          required
                          disabled={customerId ? !editMode : false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.contactName')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setContactName(e.target.value)
                          }
                          name='name'
                          value={contactName}
                          disabled={customerId ? !editMode : false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.contactNumber')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setContactNumber(e.target.value)
                          }
                          name='name'
                          value={contactNumber}
                          disabled={customerId ? !editMode : false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.emailAddress')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setEmailAddress(e.target.value)
                          }
                          name='name'
                          value={emailAddress}
                          disabled={customerId ? !editMode : false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.websiteOrSocial')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setWebsiteOrSocial(e.target.value)
                          }
                          name='name'
                          value={websiteOrSocial}
                          disabled={customerId ? !editMode : false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.physicalAddress')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setPhysicalAddress(e.target.value)
                          }
                          name='name'
                          value={physicalAddress}
                          disabled={customerId ? !editMode : false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.postalAddress')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setPostalAddress(e.target.value)
                          }
                          name='name'
                          value={postalAddress}
                          disabled={customerId ? !editMode : false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextValidator
                          label={t('labels.industryType')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setIndustryType(e.target.value)
                          }
                          name='name'
                          value={industryType}
                          disabled={customerId ? !editMode : false}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Grid container item className='setting-panel-header' alignItems='center'>
                  <RoomPreferencesOutlinedIcon />
                  <h2>{t('labels.preferences')}</h2>
                </Grid>
                <Divider />
                <div className='setting-panel-body'>
                  <Grid container className='form-controls' spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        select
                        label={t('labels.preferredContactMethod')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPreferredContactMethod(e.target.value)
                        }
                        name='preferredContactMethod'
                        disabled={customerId ? !editMode : false}
                        value={preferredContactMethod}
                      >
                        <MenuItem value={t('labels.phone')}>{t('labels.phone')}</MenuItem>
                        <MenuItem value={t('labels.email')}>{t('labels.email')}</MenuItem>
                        <MenuItem value={t('labels.socialMedia')}>
                          {t('labels.socialMedia')}
                        </MenuItem>
                      </TextValidator>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextValidator
                        select
                        label={t('labels.preferredCurrency')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPreferredCurrency(e.target.value)
                        }
                        name='preferredContactMethod'
                        disabled={customerId ? !editMode : false}
                        value={preferredCurrency}
                      >
                        {CURRENCIES_LIST.map((el) => (
                          <MenuItem key={el.code} value={el.code}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </TextValidator>
                    </Grid>
                  </Grid>
                </div>
                {(editMode || !customerId) && (
                  <Grid container className='buttons-row pb-3' justifyContent='flex-end'>
                    {customerId && (
                      <IconButton
                        aria-label='delete'
                        style={{ marginLeft: '0.5rem' }}
                        onClick={() => setDeleteCustomerMessageVisible(true)}
                      >
                        <Tooltip title={t('messages.deleteCustomer')} placement='top' arrow>
                          <DeleteOutlineOutlinedIcon fontSize='large' style={{ marginRight: 0 }} />
                        </Tooltip>
                      </IconButton>
                    )}
                    <Button
                      type='submit'
                      role='button'
                      disabled={!canSubmit}
                      style={{ marginLeft: '0.5rem' }}
                      variant='contained'
                      size='large'
                    >
                      {editMode ? t('buttons.update') : t('buttons.createCustomer')}
                    </Button>
                  </Grid>
                )}
              </ValidatorForm>
            </div>
          </Grid>
        </div>
      )}
      {pendingStatus ? <ProgressOverlay /> : null}
      <DialogItem
        isErrorMessage={false}
        open={deleteCustomerMessageVisible}
        title={
          deleteCustomerMessageVisible
            ? t('dialogTitles.deleteCustomer')
            : t('dialogTitles.deleteDefault')
        }
        text={
          deleteCustomerMessageVisible
            ? t('messages.deleteCustomerDialog')
            : t('messages.deleteDefault')
        }
        noDefaultActionsRow={true}
        handleClose={() => {
          setDeleteCustomerMessageVisible(false);
        }}
      >
        <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
          <Button
            type='button'
            role='button'
            variant='outlined'
            size='medium'
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            onClick={() => deleteCustomer()}
          >
            {t('buttons.delete')}
          </Button>
          <Button
            type='button'
            role='button'
            variant='contained'
            size='medium'
            style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
            onClick={() => {
              setDeleteCustomerMessageVisible(false);
            }}
          >
            {t('buttons.goBack')}
          </Button>
        </div>
      </DialogItem>
    </>
  );
};

export default CustomerProfileForm;
