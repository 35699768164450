import React from 'react';
import FeaturesCarousel from '../../common/features-slider/FeaturesCarousel';
import { useTranslation } from 'react-i18next';

const FeaturesContentLogIn = () => {
  const { t } = useTranslation();
  const itemsArray = [
    {
      imageSrc: '/assets/images/feature-documents-upload.jpg',
      slideTitle: t('slideTitles.documentUpload'),
      descriptionText: t('slidesDescription.easilyUpload'),
      withReleasedLabel: true,
    },
    {
      imageSrc: '/assets/images/feature-workflow-tagging.jpg',
      slideTitle: t('slideTitles.workflowTagging'),
      descriptionText: t('slidesDescription.seamlesslySelect'),
    },
    {
      imageSrc: '/assets/images/feature-e-signing.jpg',
      slideTitle: t('slideTitles.digitalSigning'),
      descriptionText: t('slidesDescription.swiftlySign'),
      withReleasedLabel: true,
    },
    {
      imageSrc: '/assets/images/feature-notifications.jpg',
      slideTitle: t('slideTitles.notifications'),
      descriptionText: t('slidesDescription.convenientlyReceive'),
    },
  ];
  return <FeaturesCarousel itemsArray={itemsArray} />;
};

export default FeaturesContentLogIn;
